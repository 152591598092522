import React from 'react';
import CustomBreadcrumb from '../../components/CustomBreadcrumb';
import LinkWithQuery from '../../components/LinkWithQuery';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

const SuccessProgram = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	return (
		<Layout>
			<Seo
				title="Hearing Success Program"
				description="hear.com's Hearing Success Program uniquely offers support & free hearing aid adjustments to adapt to new hearing aids."
			/>
			<div className="o-row-4p5">
				<section className="c-program-hero c-program-img-overlay">
					<div className="o-wrapper">
						<h1 className="c-program-headline">
							Hearing Success Program<sup>&reg;</sup>
						</h1>
					</div>
				</section>
			</div>
			<div className="o-wrapper">
				<div className="o-row-2p5">
				<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<div className="o-row-4p5">
					<div className="o-row-2">
						<p className="c-program__copy">
							You actually hear with your brain and not with your ears. This is why hearing well is so essential to
							maintaining your mental sharpness, youthfulness, and overall health. Many people expect hearing aids to
							provide a perfect solution the instant they put them on. Sometimes this happens, but often that's not the
							case. Research shows it takes at least 30 days for your brain to adjust and relearn how to hear again with
							new hearing aids.
						</p>
					</div>
					<p className="c-program__copy">
						To guarantee your best outcome with hearing aids, we developed the industry's first 30-day program to help
						your brain adjust. It's our secret sauce and the reason our customers are twice as happy as the industry
						average. It's been developed with the leading hearing care experts, is based on the latest scientific
						insights, and includes feedback from thousands of customers. If you run into any issues along the way, you
						can reach our dedicated experts at 833.560.0663 for additional support.
					</p>
				</div>

				<div className="o-row-4p5">
					<section className="c-program-header-block c-program-img-overlay">
						<h2 className="c-program-headline">30 days to better hearing</h2>
					</section>
				</div>

				<div className="o-row-4p5">
					<section className="c-program-plan">
						<div className="c-program-plan__right">
							<div className="c-program-plan__triangle"></div>
							<div className="o-row-2">
								<h3 className="c-program-plan__title">Before you put them on</h3>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Be proud about taking the first step towards better hearing and improving your overall health.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Know what to expect in your first days with new hearing aids by reading <LinkWithQuery to="/resources/hearing-aid-success/why-slow-and-steady-wins-the-race-with-new-hearing-aids/" className='c-md-a'>"Why Slow and Steady Wins the
									Race to Better Hearing"</LinkWithQuery>.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Bring a friend or family member to your fitting appointment. It's awesome to hear a familiar voice
									when you try them on and it helps with the fitting.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Commit to your success. Since it can take up to 30 days for your brain to adjust, your commitment is
									key to making it work.
								</p>
							</div>
						</div>
						<div className="c-program-plan__left">
							<img
								className="o-fluid-img"
								srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-success-program/week-0.jpg"
								alt=""
								loading="lazy"
							/>
						</div>
					</section>
					<section className="c-program-plan">
						<div className="c-program-plan__right">
							<div className="c-program-plan__triangle"></div>
							<div className="o-row-2">
								<h3 className="c-program-plan__title">Week 1: Slow and Steady</h3>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Wear your hearing aids every day. Recognize that it will take time for your brain get accustomed to the new and better hearing situation, 
									but that will only happen if oyu wear your hearing aids regularly.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Start wearing your hearing aids in quiet environments first, like your home, and experiment with easy
									listening situations like watching TV at a normal volume.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Read aloud to yourself for 10 minutes a day throughout the first week. This will help you adjust
									quickly to the new sound of your own voice.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Write down feedback on the sounds or situations that could be better. We can further personalize your
									hearing aids to your sound preferences in your follow-up appointment.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Your hear.com expert will reach out to you to ensure you're on track and see how we can support you.
								</p>
							</div>
						</div>
						<div className="c-program-plan__left">
							<img
								className="o-fluid-img"
								srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-success-program/week-1.jpg"
								alt=""
								loading="lazy"
							/>
						</div>
					</section>
					<section className="c-program-plan">
						<div className="c-program-plan__right">
							<div className="c-program-plan__triangle"></div>
							<div className="o-row-2">
								<h3 className="c-program-plan__title">Week 2: Keep up the good work</h3>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Wear your hearing aids every day for as long as it feels comfortable to you. The most important recommendation is to have no zero days, the more you wear your hearing aids, the more you are training your brain to hear better.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Explore additional easy-listening situations like talking on the phone or enjoying a quiet outdoor
									space, like your backyard. For more ideas, read <LinkWithQuery to="/resources/hearing-aids/activities-that-help-you-adjust-to-new-hearing-aids/" className='c-md-a'>"Activities That Help You Adjust to Hearing Aids"</LinkWithQuery>. 
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Listen to an audiobook and focus actively on every word. This will help to retrain your brain.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									To maximize your benefit, get your hearing aids personalized based on your sound preferences in your
									follow-up appointment with the provider.
								</p>
							</div>
						</div>
						<div className="c-program-plan__left">
							<img
								className="o-fluid-img"
								srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-success-program/week-2.jpg"
								alt=""
								loading="lazy"
							/>
						</div>
					</section>
					<section className="c-program-plan">
						<div className="c-program-plan__right">
							<div className="c-program-plan__triangle"></div>
							<div className="o-row-2">
								<h3 className="c-program-plan__title">Week 3: You're ready for the next level</h3>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Wear your hearing aids every day. By now you will probably be able to wear them almost all day.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Keep experimenting with more challenging hearing situations like the grocery store, conversations with
									multiple people, or outdoor settings.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Clean your hearing aids. If you need help, read <LinkWithQuery to="/resources/hearing-aids/how-to-keep-your-hearing-aids-in-tip-top-shape-for-years-to-come/" className='c-md-a'>"How to Keep Your Hearing Aids in Tip-Top Shape"</LinkWithQuery>.
								</p>
							</div>
						</div>
						<div className="c-program-plan__left">
							<img
								className="o-fluid-img"
								srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-success-program/week-3.jpg"
								alt=""
								loading="lazy"
							/>
						</div>
					</section>
					<section className="c-program-plan c-program-plan__right--last">
						<div className="c-program-plan__right">
							<div className="o-row-2">
								<h3 className="c-program-plan__title">Week 4: Get comfortable, the work is done</h3>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">Wear your hearing aids all day, every day.</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">Enjoy the fruits of your labor and keep exploring new situations.</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">
									Visit our <LinkWithQuery to="/faqs/" className='c-md-a'>FAQs</LinkWithQuery> or call us at 833.560.0663, whenever you have questions or need assistance.
								</p>
							</div>
							<div className="o-row-2">
								<p className="c-program__copy">Tell your friends and family how amazing it is to hear well.</p>
							</div>
						</div>
						<div className="c-program-plan__left">
							<img
								className="o-fluid-img"
								srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-success-program/week-4.jpg"
								alt=""
								loading="lazy"
							/>
						</div>
					</section>
				</div>

				<div className="o-row-4p5">
					<div className="o-row-2">
						<h3 className="c-program-plan__title">Minimum wearing schedule calendar:</h3>
					</div>
					<picture>
						<source
							media="(max-width: 768px)"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-success-program/calendar.jpg"
						/>
						<source
							media="(min-width: 769px)"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-success-program/calendar-desk.jpg"
						/>
						<img
							className="o-fluid-img c-hero__vid"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-success-program/calendar-desk.jpg"
							alt="30 Day calendar"
							loading="lazy"
						/>
					</picture>
				</div>
			</div>
		</Layout>
	);
};

export default SuccessProgram;
